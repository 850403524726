import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SectionImageOnLeft from "../../templates/SectionImageOnLeft/SectionImageOnLeft";

const About = () => (
  <StaticQuery
    query={AboutData}
    render={(data) => {
      const about = data.markdownRemark;
      return (
        <>
          {/* History */}
          <div className="container-fluid history-section" id="about">
            <SectionImageOnLeft data={about} />
          </div>
        </>
      );
    }}
  />
);

export const AboutData = graphql`
  query HistoryQuery {
    markdownRemark(
      fileAbsolutePath: {
        regex: "content/sections_content/about-the-chemistry-room/"
      }
    ) {
      frontmatter {
        title
        link
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        background_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
    }
  }
`;

export default About;
