import React from "react";
import Header from "./header.js";
import Footer from "./footer.js";
import { StaticQuery, graphql } from "gatsby";

const Layout = ({ children }) => (
  <StaticQuery
    query={ContactUs}
    render={(data) => {
      const contactUs = data.markdownRemark.frontmatter;

      return (
        <>
          <Header contactUs={contactUs} />
          {children}
          <Footer contactUs={contactUs} />
        </>
      );
    }}
  />
);

export const ContactUs = graphql`
  {
    markdownRemark(
      fileAbsolutePath: { regex: "cms/contact_us/" }
      excerptAst: {}
    ) {
      frontmatter {
        address
        chemistry_room_booking_link
        chemistry_room_address
        facebook_link
        hours
        instagram_link
        special_request
        suhi_lab_booking_link
        yelp_link
        menu_link {
          publicURL
        }
        reservation_link
        about_chemistry_room
      }
    }
  }
`;

export default Layout;
