import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Carousel } from "react-bootstrap";

const Slider = () => (
  <StaticQuery
    query={SliderData}
    render={(data) => {
      const sliderImages = data.allMarkdownRemark.edges;

      return (
        <>
          <Carousel indicators={true} controls={false}>
            {sliderImages.map((sliderImage, index) => (
              <Carousel.Item key={index}>
                <GatsbyImage
                  image={getImage(sliderImage.node.frontmatter.image)}
                  alt={sliderImage.node.frontmatter.alt}
                />
              </Carousel.Item>
            ))}
          </Carousel>

          {/* Old Carousel */}
          {/* <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              {sliderImages.map((sliderImage, index) => (
                <li
                  className={index === 0 ? "m-sm-3 active" : "m-sm-3"}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  key={index}
                ></li>
              ))}
            </ol>
            <div className="carousel-inner">
              {sliderImages.map((sliderImage, index) => (
                <div
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                  key={index}
                >
                  <div className="d-block w-100">
                    <GatsbyImage
                      image={getImage(sliderImage.node.frontmatter.image)}
                      alt={sliderImage.node.frontmatter.alt}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </>
      );
    }}
  />
);

export const SliderData = graphql`
  query SliderQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "content/slider/" } }
    ) {
      edges {
        node {
          frontmatter {
            alt
            image {
              childImageSharp {
                gatsbyImageData(aspectRatio: 2)
              }
            }
          }
        }
      }
    }
  }
`;

export default Slider;
