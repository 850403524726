import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import logo from "../../images/logo.webp";
import MobileLogo from "../../images/logo-small.webp";
import InstaLogo from "../../images/Insta-04.svg";
import FbLogo from "../../images/Footer-fb.svg";
import HamburgerIcon from "../../images/hamburger-icon.svg";

const Header = ({ contactUs }) => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=yes"
        />
        <meta
          name="description"
          content="The Chemistry Room by Sushi Lab is an innovative concept with a unique take on traditional Japanese cuisine. With culinary inspirations from Japan, France and Italy, The Chemistry Room offers dining options with the highest quality of sourced items that bring together subtle and robust flavors. For sushi lovers that seek Umami (essence of deliciousness), The Chemistry Room is truly a journey for the senses."
        />
        <title>Chemistry Room</title>
      </Helmet>

      <nav className="navbar navbar-expand-md w-100 sp-nav">
        <div className="navbar-collapse collapse order-1 order-md-0 dual-collapse2 d-sm-none d-none d-md-block">
          <Link to="/" aria-label="Home Page Link">
            <img
              className="logo"
              src={logo}
              alt="Logo"
              height="70px"
              width="auto"
            />
          </Link>
        </div>

        <div className="navbar-collapse w-100 order-3 dual-collapse2 d-none d-sm-none d-md-block">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/#about" className="nav-link main-nav-link fz-18">
                About
              </Link>
            </li>

            <li className="nav-item">
              <a
                href={contactUs.menu_link.publicURL}
                className="nav-link main-nav-link fz-18"
                target="_blank"
                rel="noopener noreferrer"
              >
                Menu
              </a>
            </li>

            <li className="nav-item">
              <Link
                to="/#private-events"
                className="nav-link main-nav-link fz-18"
              >
                Private events
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/#gallery" className="nav-link main-nav-link fz-18">
                Gallery
              </Link>
            </li>

            <li className="nav-item">
              <a
                href={contactUs.reservation_link}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link main-nav-link fz-18"
              >
                Reservation
              </a>
            </li>

            {contactUs.instagram_link !== undefined &&
            contactUs.instagram_link ? (
              <li className="nav-item">
                <a
                  className="nav-link main-nav-link"
                  href={contactUs.instagram_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram Link"
                >
                  <img height="16" src={InstaLogo} alt="" />
                </a>
              </li>
            ) : null}

            {contactUs.facebook_link !== undefined &&
            contactUs.facebook_link ? (
              <li className="nav-item">
                <a
                  className="nav-link main-nav-link"
                  href={contactUs.facebook_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook Link"
                >
                  <img height="16" src={FbLogo} alt="" />
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>

      <ul className="d-md-none w-100 social-ul">
        {contactUs.instagram_link !== undefined && contactUs.instagram_link ? (
          <li className="nav-item">
            <a
              className="nav-link main-nav-link"
              href={contactUs.instagram_link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram Link"
            >
              <img height="16" src={InstaLogo} alt="" />
            </a>
          </li>
        ) : null}

        {contactUs.facebook_link !== undefined && contactUs.facebook_link ? (
          <li className="nav-item">
            <a
              className="nav-link main-nav-link"
              href={contactUs.facebook_link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook Link"
            >
              <img src={FbLogo} alt="" />
            </a>
          </li>
        ) : null}
      </ul>

      <div className="dropdown d-block d-md-none">
        <button
          className="mt-2 hamburger-icon"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
        >
          <img src={HamburgerIcon} alt="" />
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <Link to="/#about" className="nav-link main-nav-link">
            About
          </Link>
          <a
            href={contactUs.menu_link.publicURL}
            className="nav-link main-nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Menu
          </a>

          <Link to="/#private-events" className="nav-link main-nav-link">
            Private events
          </Link>
          <Link to="/#gallery" className="nav-link main-nav-link">
            Gallery
          </Link>
          <a
            href={contactUs.reservation_link}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link main-nav-link"
          >
            Reservation
          </a>
        </div>
        <img className="logo resp-logo" src={MobileLogo} alt="" />
      </div>
    </>
  );
};

export default Header;
