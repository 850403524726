import React from "react";
import * as Style from "./ProgressBar.module.css";

const ProgressBar = ({ progress }) => {
  return (
    <div className={Style.Container}>
      <div className={Style.LoaderText}>
        <div>The Chemistry Room</div>
        <span> ...</span>
      </div>
      <div className={Style.ProgressBarContainer}>
        <div
          className={Style.ProgressBar}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
