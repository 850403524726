import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const SectionImageOnRight = ({ data }) => {
  const backgroundImageSrcSet = getImage(data.frontmatter.background_image);
  const backgroundImage = backgroundImageSrcSet.images.fallback.src;

  const imageSrcSet = getImage(data.frontmatter.image);

  return (
    <div className="row h-100vh section-height">
      <GatsbyImage
        image={imageSrcSet}
        as="div"
        className="col-md-7 col-12 history-section-img d-block d-md-none"
        alt=""
      />
      <div
        className="col-md-5 col-12 history-section-content"
        style={{
          background: `linear-gradient(0deg,rgba(17,32,55,.8),rgba(17,32,55,.8)),url(${backgroundImage})`,
        }}
      >
        <p className="pale-yellow">
          <b className="fz-22">{data.frontmatter.title}</b>
          <br />
        </p>
        <span
          className="pale-yellow fz-17 fw-300 text-align-justify"
          dangerouslySetInnerHTML={{ __html: data.html }}
        ></span>
        {data.frontmatter.link ? (
          <a
            href={data.frontmatter.link}
            className="btn btn-outline-book float-right"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Here
          </a>
        ) : (
          ""
        )}
      </div>
      <GatsbyImage
        image={imageSrcSet}
        as="div"
        className="col-md-7 col-12 history-section-img d-none d-md-block"
        alt=""
      />
    </div>
  );
};

export default SectionImageOnRight;
