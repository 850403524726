import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const GalleryItem = (props) => {
  const imageSrcSet = getImage(
    props.data.node.frontmatter.image.childrenImageSharp[0]
  );

  return (
    <GatsbyImage
      image={imageSrcSet}
      as="div"
      key={props.index}
      className="photo"
      alt=""
    />
  );
};

export default GalleryItem;
