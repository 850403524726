import React from "react";
import { useState } from "react";
import { Remarkable } from "remarkable";
import FooterLogo from "../../images/logo-footer.webp";
import FooterInsta from "../../images/Footer-insta.svg";
import FooterBackGround from "../../images/footer-background.webp";
import { Script } from "gatsby";

var md = new Remarkable();

const Footer = ({ contactUs }) => {
  const [subject, setSubject] = useState();

  return (
    <>
      <div
        className="container-fluid footer-container"
        id="location"
        style={{
          background: `linear-gradient(0deg, rgba(17, 32, 55, 0.8), rgba(17, 32, 55, 0.8)), url(${FooterBackGround})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "70px",
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-2">
            <div className="footer-content w-100 sp-text-color">
              <div>
                <img
                  className="footer-logo pl-0"
                  src={FooterLogo}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div>
                <p>
                  <b>SPECIAL REQUESTS?</b>
                </p>
                <p>{contactUs.special_request}</p>

                <p>
                  <b>THE CHEMISTRY ROOM</b>
                </p>
                <div>
                  <div className="row m-0">
                    <div className="col-12 col-sm-9 col-md-9 p-0">
                      <p>
                        {contactUs.about_chemistry_room !== undefined &&
                        contactUs.about_chemistry_room
                          ? contactUs.about_chemistry_room
                          : null}
                      </p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: md.render(contactUs.chemistry_room_address),
                        }}
                      ></span>
                    </div>
                    <div className="col-12 col-sm-3 col-md-3 p-0">
                      <a
                        href={contactUs.chemistry_room_booking_link}
                        className="btn btn-outline-book"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Book Here
                      </a>
                    </div>
                  </div>
                </div>

                {/* <p>
                  <b>HOURS</b>
                </p>
                <span
                  dangerouslySetInnerHTML={{ __html: md.render(contactUs.hours) }}
                ></span> */}
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-2">
            <div className="footer-content-form w-100">
              <div className="row m-0 pt-4 pt-sm-0">
                <div className="col-10">
                  <div className="footer-form">
                    <span className="sp-text-color pl-1">
                      <b>CONTACT US</b>
                    </span>
                    <br />
                    <form name="contact" method="POST" data-netlify="true">
                      <input type="hidden" name="form-name" value="contact" />
                      <input type="hidden" name="subject" value={subject} />
                      <input
                        className="w-100 footer-input m-1 pale-border sp-text-color"
                        type="text"
                        placeholder="NAME"
                        name="name"
                        required
                        onChange={(e) =>
                          setSubject(
                            "Chemistry Room Form submission by " +
                              e.target.value
                          )
                        }
                      />
                      <input
                        className="w-100 footer-input m-1 pale-border"
                        type="text"
                        placeholder="PHONE"
                        name="phone"
                        required
                      />
                      <input
                        className="w-100 footer-input m-1 pale-border"
                        type="text"
                        placeholder="EMAIL"
                        name="email"
                        required
                      />
                      <textarea
                        className="w-100 footer-input m-1 pale-border"
                        placeholder="HOW CAN WE SERVE YOU?"
                        id=""
                        cols="30"
                        rows="7"
                        name="description"
                        required
                      ></textarea>
                      <button
                        type="submit"
                        className="w-100 send-btn border-0 m-1"
                      >
                        <b>SEND</b>
                      </button>
                    </form>
                  </div>
                </div>

                <div className="col-2">
                  <div className="footer-form-icons">
                    <a
                      href={contactUs.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram Link"
                    >
                      <img
                        height="34"
                        className="mt-3"
                        src={FooterInsta}
                        alt=""
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-4">
            <p className="text-center p-2 pale-text">
              COPYRIGHT &copy; {new Date().getFullYear()} CHEMISTRY ROOM - ALL
              RIGHTS RESERVED
            </p>
          </div>
        </div>
      </div>
      <Script
        key="change header background color"
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `
        $(function () {
          $(document).scroll(function () {
            var $nav = $(".sp-nav");
            var $ul = $(".social-ul");
            if(window.scrollY === 0) {
              $nav.removeClass('bg-brown');
              $ul.removeClass('bg-sm-brown');
            } else {
              $nav.addClass('bg-brown');
              $ul.addClass('bg-sm-brown');
            }
          });
        });
      `,
        }}
      />
      <Script
        key="accessiBe script"
        dangerouslySetInnerHTML={{
          __html: `
        (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, triggerRadius : '20' } }); }; h.appendChild(s); })();
      `,
        }}
      />
    </>
  );
};

export default Footer;
