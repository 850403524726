import React from "react";
import { StaticQuery, graphql, Link, Script } from "gatsby";
import GalleryItem from "./Template/GalleryItem";
import GalleryModal from "./GalleryModal/GalleryModal";

const Gallery = () => (
  <StaticQuery
    query={GalleryData}
    render={(data) => {
      const galleryImages = data.allMarkdownRemark.edges;

      return (
        <>
          <div className="gallery-photos" id="gallery">
            {/* <div className="gallery-heading">
            <div className="h1 mb-5 offset-2 fz-65">The Gallery</div>
          </div> */}

            <div className="photos-wrap mCustomScrollbar _mCS_1">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-dark-thin mCSB_horizontal mCSB_inside"
                style={{ maxHeight: "none" }}
              >
                <div id="mCSB_1_container" className="mCSB_container" dir="ltr">
                  {galleryImages.map((galleryImage, index) => (
                    <GalleryItem data={galleryImage} index={index} />
                  ))}
                </div>
              </div>
            </div>

            <p className="see-all-photos-a text-center mt-5">
              <Link
                to="/#gallery"
                data-toggle="modal"
                data-target="#galleryModel"
              >
                <b>SEE ALL PHOTOS</b>
              </Link>
            </p>
          </div>

          <div
            className="modal fade"
            id="galleryModel"
            // tabindex="-1"
            role="dialog"
            aria-labelledby="galleryModelTitle"
            aria-hidden="true"
            style={{ zIndex: 10000 }}
          >
            <div
              className={"modal-dialog modal-dialog-scrollable modal-xl m-auto"}
              style={{ maxWidth: "1102px" }}
              role="document"
            >
              <div className="modal-content" style={{ background: "#232B3A" }}>
                <div className="modal-header" style={{ borderBottom: "0px" }}>
                  <h5
                    className="modal-title"
                    id="exampleModalLongTitle"
                    style={{ color: "#D3CBB0", fontSize: "1.5rem" }}
                  >
                    Gallery
                  </h5>
                  <button
                    style={{ color: "#D3CBB0", fontSize: "1.5rem" }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span
                      aria-hidden="true"
                      style={{ color: "#D3CBB0", fontSize: "1.5rem" }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ background: "#232B3A", fontSize: "1.5rem" }}
                >
                  <div className="d-flex flex-wrap">
                    {galleryImages.map((galleryImage, index) => (
                      <div key={index}>
                        <GalleryModal data={galleryImage} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Script
            key="gallery"
            strategy="idle"
            dangerouslySetInnerHTML={{
              __html: `
          (function($){
              $(window).on("load",function(){
                $(".gallery-photos").mCustomScrollbar();
              });
            })(jQuery);
            
            $(".gallery-photos .photos-wrap, .events-gallery .photos-wrap").mCustomScrollbar({
              axis:"x",
              theme:"dark-thin",
              autoExpandScrollbar:false,
              advanced:{autoExpandHorizontalScroll:false},
            });
            `,
            }}
          />
        </>
      );
    }}
  />
);

export const GalleryData = graphql`
  query GalleryQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "content/gallery_images/" } }
    ) {
      edges {
        node {
          frontmatter {
            alt
            image {
              childrenImageSharp {
                gatsbyImageData(formats: WEBP)
              }
            }
          }
        }
      }
    }
  }
`;

export default Gallery;
