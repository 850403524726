import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SectionImageOnLeft from "../../templates/SectionImageOnLeft";

const PrivateDining = () => (
  <StaticQuery
    query={PrivateDiningData}
    render={(data) => {
      const privateDining = data.markdownRemark;

      return (
        <>
          {/* Private Dining */}

          <div className="container-fluid history-section" id="private-events">
            <SectionImageOnLeft data={privateDining} />
          </div>
        </>
      );
    }}
  />
);

export const PrivateDiningData = graphql`
  query PrivateDiningQuery {
    markdownRemark(
      fileAbsolutePath: { regex: "cms/sections_content/private-events/" }
    ) {
      frontmatter {
        title
        link
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        background_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
    }
  }
`;

export default PrivateDining;
