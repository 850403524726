import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AboutTheChemistryRoom from "../components/AboutTheChemistryRoom";
import Gallery from "../components/Gallery/Gallery";
import Layout from "../components/layouts/layout";
import PrivateDining from "../components/PrivateDining";
import Slider from "../components/Slider";
import TheDiningExperience from "../components/TheDiningExperience";
import ProgressBar from "../components/ProgressBar";

let interval = undefined;
const IndexPage = () => {
  const [progress, setProgress] = useState(1);
  const [progressBar, setProgressBar] = useState(true);

  useEffect(() => {
    if (progressBar) {
      interval = setInterval(() => {
        setProgress((prev) => prev + 1);
      }, 10);
    }
  }, [progressBar]);

  useEffect(() => {
    if (progress === 100) {
      setProgressBar(false);
      clearInterval(interval);
    }
  }, [progress]);
  return (
    <>
      {progressBar === true ? <ProgressBar progress={progress} /> : null}

      <Layout>
        {/* Slider */}

        {progressBar === true ? null : (
          <>
            <Slider />

            {/* Sushi Lab */}
            <AboutTheChemistryRoom />

            {/* Chemistry Room */}
            <TheDiningExperience />

            {/* Private Events */}
            <PrivateDining />

            {/* Gallery */}
            <Gallery />
          </>
        )}
      </Layout>
    </>
    // <>
    //   {progressBar === true ? (
    //     <ProgressBar progress={progress} />
    //   ) : (
    //     <Layout>
    //       {/* Slider */}
    //       <Slider />

    //       {/* Sushi Lab */}
    //       <AboutTheChemistryRoom />

    //       {/* Chemistry Room */}
    //       <TheDiningExperience />

    //       {/* Private Events */}
    //       <PrivateDining />

    //       {/* Gallery */}
    //       <Gallery />
    //     </Layout>
    //   )}
    // </>
  );
};

export default IndexPage;
