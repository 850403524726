import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const GalleryModal = (props) => {
  const imageSrcSet = getImage(
    props.data.node.frontmatter.image.childrenImageSharp[0]
  );

  return (
    <GatsbyImage
      image={imageSrcSet}
      as="div"
      imgClassName="p-3 img-fluid"
      key={props.index}
      imgStyle={{ width: "350px", height: "450px" }}
      style={{ width: "350px", height: "450px" }}
      alt=""
    />
  );
};

export default GalleryModal;
