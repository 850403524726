import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SectionImageOnRight from "../../templates/SectionImageOnRight";

const DiningExperience = () => (
  <StaticQuery
    query={DiningExperienceData}
    render={(data) => {
      const diningExperience = data.markdownRemark;

      return (
        <>
          {/* Florida */}
          <div className="container-fluid history-section" id="florida">
            <SectionImageOnRight data={diningExperience} />
          </div>
        </>
      );
    }}
  />
);

export const DiningExperienceData = graphql`
  query FloridaQuery {
    markdownRemark(
      fileAbsolutePath: { regex: "content/sections_content/the-dining-experience/" }
    ) {
      frontmatter {
        title
        link
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        background_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
    }
  }
`;

export default DiningExperience;
